<template>
  <div class="row">
    <div class="col-md-12">
      <KTCodePreview v-bind:title="'Form Sertifikasi Perizinan'">
        <template v-slot:preview>
          <b-row>
            <b-col md="12">
              <b-form class="form" @submit.stop.prevent="onSubmit">
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="nomor_sertifikat_label"
                  label="Nomor Sertifikat :"
                  label-for="nomor_sertifikat"
                >
                  <b-form-input
                    id="nomor_sertifikat"
                    v-model="form.nomor_sertifikat"
                    type="text"
                    placeholder="Enter Text..."
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="nomor_peralatan_label"
                  label="Nomor Peralatan :"
                  label-for="nomor_peralatan"
                >
                  <b-form-input
                    id="nomor_peralatan"
                    v-model="form.nomor_peralatan"
                    type="text"
                    placeholder="Enter Text..."
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="nama_peralatan_label"
                  label="Nama Peralatan :"
                  label-for="nama_peralatan"
                >
                  <b-form-input
                    id="nama_peralatan"
                    v-model="form.nama_peralatan"
                    type="text"
                    placeholder="Enter Text..."
                  ></b-form-input>

                  <!-- <b-form-invalid-feedback id="nama_peralatan_feedback">
                    Nama Peralatan Harus Terisi.
                  </b-form-invalid-feedback> -->
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="kategori_peralatan_label"
                  label="Kategori Peralatan :"
                  label-for="kategori_peralatan"
                >
                  <vue-suggestion
                    :items="optionsKategori"
                    v-model="itemKategoriPeralatan"
                    :setLabel="setLabel"
                    :itemTemplate="itemTemplate"
                    @changed="inputChangeKategoriPeraltan"
                    @selected="itemSelectedKategoriPeralatan"
                    inputClasses="form-control"
                    placeholder="Enter Text..."
                  >
                  </vue-suggestion>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="lokasi_label"
                  label="Lokasi :"
                >
                  <vue-suggestion
                    :items="optionsLokasi"
                    v-model="itemLokasiSertifikasiPerizinan"
                    :setLabel="setLabel"
                    :itemTemplate="itemTemplate"
                    @changed="inputLokasiSertifikasiPerizinan"
                    @selected="itemSelectedLokasiSertifikasiPerizinan"
                    inputClasses="form-control"
                    placeholder="Enter Text..."
                  >
                  </vue-suggestion>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="mulai_berlaku_label"
                  label="Mulai Berlaku :"
                  label-for="mulai_berlaku"
                >
                  <b-form-input
                    id="mulai_berlaku"
                    v-model="form.mulai_berlaku"
                    type="date"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="masa_berlaku_label"
                  label="Masa Berlaku :"
                  label-for="masa_berlaku"
                >
                  <b-form-input
                    id="masa_berlaku"
                    v-model="form.masa_berlaku"
                    type="text"
                    placeholder="Enter Text..."
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="berlaku_sampai_label"
                  label="Berlaku Sampai :"
                  label-for="berlaku_sampai"
                >
                  <b-form-input
                    id="berlaku_sampai"
                    v-model="form.berlaku_sampai"
                    type="date"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="disahkan_oleh_label"
                  label="Disahkan Oleh :"
                  label-for="disahkan_oleh"
                >
                  <b-form-input
                    id="disahkan_oleh"
                    v-model="form.disahkan_oleh"
                    type="text"
                    placeholder="Enter Text..."
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="attachment_sertifikat_label"
                  label="Attachment :"
                  label-for="attachment_sertifikat"
                >
                  <b-form-file
                    id="attachment_sertifikat"
                    v-model="form.attachment_sertifikat"
                    placeholder="Choose a photo or file here..."
                    drop-placeholder="Drop file here..."
                  ></b-form-file>
                </b-form-group>

                <hr />
                <h6>History Pemeriksaan</h6>
                <b-row v-if="!isBusy">
                  <b-col md="10" offset-md="1">
                    <b-button class="ml-3" variant="success" size="sm" @click="addHistory">
                      <span class="svg-icon svg-icon-sm svg-icon-light">
                        <i class="flaticon2-plus"></i>
                      </span>
                      Add History
                    </b-button>
                    <ol>
                      <li v-for="(todo, index) in form.tanggal_pemeriksaan" v-bind:key="todo.index">
                        <b-row class="pl-3">
                          <b-col>
                            <b-form-group
                              :id="'tanggal_pemeriksaan_label_' + index"
                              label="Tanggal Pemeriksaan :"
                              :label-for="'tanggal_pemeriksaan_' + index"
                            >
                              <b-form-input
                                :id="'tanggal_pemeriksaan_' + index"
                                v-model="form.tanggal_pemeriksaan[index]"
                                type="date"
                              ></b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-form-group
                              :id="'attachment_history_label_' + index"
                              label="Attachment :"
                              :label-for="'attachment_history_' + index"
                              description="Upload hanya jika ingin mengubah file."
                            >
                              <b-form-file
                                :id="'attachment_history_' + index"
                                v-model="form.attachment_history[index]"
                                placeholder="Choose a photo or file here..."
                                drop-placeholder="Drop file here..."
                              ></b-form-file>
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-button
                              v-if="url[index]"
                              class="mt-8"
                              variant="primary"
                              size="sm"
                              :href="url[index]"
                              target="_blank"
                            >
                              <span class="svg-icon svg-icon-sm svg-icon-light">
                                <i class="flaticon-download text-info"></i>
                              </span>
                            </b-button>
                            <b-button
                              v-if="url[index]"
                              class="mt-8 ml-2"
                              variant="danger"
                              size="sm"
                              v-on:click="delete_history(index)"
                            >
                              <span class="svg-icon svg-icon-sm svg-icon-light">
                                <i class="flaticon2-trash text-danger"></i>
                              </span>
                            </b-button>
                            <b-button
                              v-else
                              class="mt-8 ml-2"
                              variant="danger"
                              size="sm"
                              v-on:click="form.tanggal_pemeriksaan.splice(index, 1)"
                            >
                              <span class="svg-icon svg-icon-sm svg-icon-light">
                                <i class="flaticon2-trash text-danger"></i>
                              </span>
                            </b-button>
                          </b-col>
                        </b-row>
                      </li>
                    </ol>
                  </b-col>
                </b-row>

                <br />
                <hr />

                <b-button
                  variant="outline-dark"
                  :disabled="loading"
                  @click="$router.push('/sertifikasiperizinan')"
                >
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <span v-else class="svg-icon svg-icon-sm svg-icon-dark">
                    <i class="flaticon2-left-arrow-1"></i>
                  </span>
                  Discard
                </b-button>

                <b-button class="ml-3" type="submit" variant="outline-success" :disabled="loading">
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <!-- <strong>Loading...</strong> -->
                  <span v-else class="svg-icon svg-icon-sm svg-icon-success">
                    <i class="flaticon2-check-mark"></i>
                  </span>
                  Submit
                </b-button>
              </b-form>
            </b-col>
          </b-row>
          <b-modal ref="error-modal" title="Peringatan!">
            <b-alert class="pl-2" variant="danger" :fade="true" v-model="showDismissibleAlert">
              <br />
              <span v-for="(val, index) in errors" :key="index">
                <ul>
                  <li v-for="(arr, i) in val" :key="i">
                    {{ arr }}
                  </li>
                </ul>
                <br />
              </span>
            </b-alert>
          </b-modal>
        </template>
      </KTCodePreview>
    </div>
  </div>
</template>
<style lang="scss">
@import '~vuetify/dist/vuetify.css';

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
// import { mapState } from "vuex";

import ApiService from '@/core/services/api.service'
import { validationMixin } from 'vuelidate'
// import { required } from "vuelidate/lib/validators";
import $ from 'jquery'

import itemTemplate from '@/view/pages/item-template.vue'

export default {
  mixins: [validationMixin],
  name: 'editsertifikasiperizinan',
  data() {
    return {
      form: {
        nomor_peralatan: null,
        lokasi_sertifikasi_perizinan_id: null,
        nomor_sertifikat: null,
        nama_peralatan: null,
        kategori_peralatan: null,
        mulai_berlaku: null,
        masa_berlaku: null,
        berlaku_sampai: null,
        disahkan_oleh: null,
        attachment_sertifikat: null,
        attachment_history: [],
        tanggal_pemeriksaan: [],
        history_id: [],
      },
      url: [],
      equipment: [],
      optionsLokasi: [],
      optionsKategori: [],

      itemTemplate,
      itemKategoriPeralatan: null,
      itemLokasiSertifikasiPerizinan: null,

      showDismissibleAlert: false,
      errors: [],
      loading: false,
      isBusy: false,
      id: 0,
    }
  },
  // validations: {
  //   form: {
  //     nama_peralatan: {
  //       required,
  //     },
  //   },
  // },
  components: {
    KTCodePreview,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Sertifikasi Perizinan', route: '/sertifikasiperizinan' },
      { title: 'Edit' },
    ])
  },
  created() {
    var self = this
    this.id = this.$route.params.id

    ApiService.get('/sertifikasiperizinan/edit/' + self.id)
      .then(({ data }) => {
        if (data.status == 'ok') {
          self.form.nomor_peralatan = data.data.nomor_peralatan
          self.form.plant_id = data.data.plant_id
          self.form.nomor_sertifikat = data.data.nomor_sertifikat
          self.form.nama_peralatan = data.data.nama_peralatan

          self.itemKategoriPeralatan = {
            id: data.data.id_kategori_peralatan,
            name: data.data.nama_kategori_peralatan,
          }
          self.form.kategori_peralatan = data.data.id_kategori_peralatan

          self.itemLokasiSertifikasiPerizinan = {
            id: data.data.id_lokasi_sertifikasi_perizinan,
            name: data.data.nama_lokasi_sertifikasi_perizinan,
          }
          self.form.lokasi_sertifikasi_perizinan_id = data.data.id_lokasi_sertifikasi_perizinan

          self.form.mulai_berlaku = data.data.mulai_berlaku
          self.form.masa_berlaku = data.data.masa_berlaku
          self.form.berlaku_sampai = data.data.berlaku_sampai
          self.form.disahkan_oleh = data.data.disahkan_oleh

          var history = data.data.history
          for (let i = 0; i < history.length; i++) {
            self.form.history_id.push(history[i].id_history_pemeriksaan)
            self.form.tanggal_pemeriksaan.push(history[i].tanggal_pemeriksaan)
            self.form.attachment_history.push(null)
            self.url.push(
              ApiService.urlStorage() + '/file/history_sertifikasi/' + history[i].attachment_history
            )
          }
        }
      })
      .catch(response => {
        console.log(response)
      })
  },
  methods: {
    addHistory: function() {
      this.form.history_id.push(0)
      this.form.tanggal_pemeriksaan.push('')
      this.form.attachment_history.push(null)
      this.url.push(null)
    },
    // validateState(name) {
    //   const { $dirty, $error } = this.$v.form[name];
    //   return $dirty ? !$error : null;
    // },
    delete_history(index) {
      var history_id = this.form.history_id[index]
      let self = this
      this.$confirm({
        auth: false,
        message: 'Anda yakin akan menghapus data tersebut?',
        button: {
          yes: 'Ya',
          no: 'Batal',
        },
        callback: confirm => {
          if (confirm) {
            ApiService.get('/sertifikasiperizinan/delete_history/' + history_id).then(
              ({ data }) => {
                if (data.status == 'ok') {
                  self.isBusy = true
                  var interval1 = null
                  var i = 0
                  interval1 = setInterval(function() {
                    if (i == 0) {
                      self.form.history_id.splice(index, 1)
                      self.form.tanggal_pemeriksaan.splice(index, 1)
                      self.form.attachment_history.splice(index, 1)
                      self.url.splice(index, 1)

                      self.makeToast('warning', 'Data berhasil dihapus')
                      self.isBusy = false
                    } else {
                      clearInterval(interval1)
                    }
                    i++
                  }, 1000)
                }
              }
            )
          }
        },
      })
    },
    onSubmit() {
      // event.preventDefault()
      // this.$v.form.$touch();
      // if (this.$v.form.$anyError) {
      //   return;
      // }
      var self = this
      self.loading = true
      const nama_peralatan = self.form.nama_peralatan
      const nomor_peralatan = self.form.nomor_peralatan
      const nomor_sertifikat = self.form.nomor_sertifikat

      var kategori_peralatan = null
      if (
        typeof self.form.kategori_peralatan === 'object' &&
        self.form.kategori_peralatan !== null
      ) {
        kategori_peralatan = parseInt(self.form.kategori_peralatan.id)
      } else {
        kategori_peralatan = self.form.kategori_peralatan
      }

      var lokasi_sertifikasi_perizinan_id = null
      if (
        typeof self.form.lokasi_sertifikasi_perizinan_id === 'object' &&
        self.form.lokasi_sertifikasi_perizinan_id !== null
      ) {
        lokasi_sertifikasi_perizinan_id = parseInt(self.form.lokasi_sertifikasi_perizinan_id.id)
      } else {
        lokasi_sertifikasi_perizinan_id = self.form.lokasi_sertifikasi_perizinan_id
      }

      const mulai_berlaku = self.form.mulai_berlaku
      const masa_berlaku = self.form.masa_berlaku
      const berlaku_sampai = self.form.berlaku_sampai
      const disahkan_oleh = self.form.disahkan_oleh
      const attachment_sertifikat = self.form.attachment_sertifikat

      const dataku = new FormData()
      if (nama_peralatan) dataku.append('nama_peralatan', nama_peralatan)
      if (nomor_peralatan) dataku.append('nomor_peralatan', nomor_peralatan)
      if (lokasi_sertifikasi_perizinan_id)
        dataku.append('lokasi_sertifikasi_perizinan_id', lokasi_sertifikasi_perizinan_id)
      if (nomor_sertifikat) dataku.append('nomor_sertifikat', nomor_sertifikat)
      if (kategori_peralatan) dataku.append('kategori_peralatan', kategori_peralatan)
      if (mulai_berlaku) dataku.append('mulai_berlaku', mulai_berlaku)
      dataku.append('masa_berlaku', masa_berlaku)
      dataku.append('berlaku_sampai', berlaku_sampai)
      dataku.append('disahkan_oleh', disahkan_oleh)
      if (attachment_sertifikat) {
        dataku.append('attachment_sertifikat', attachment_sertifikat)
      }

      var history_id = self.form.history_id
      var tanggal_pemeriksaan = self.form.tanggal_pemeriksaan
      var attachment_history = self.form.attachment_history
      if (tanggal_pemeriksaan.length) {
        for (let i = 0; i < tanggal_pemeriksaan.length; i++) {
          dataku.append('history_id[' + i + ']', history_id[i])
          dataku.append('tanggal_pemeriksaan[' + i + ']', tanggal_pemeriksaan[i])
          dataku.append('attachment_history[' + i + ']', attachment_history[i])
        }
      }

      var urlApi = ApiService.urlApi()

      $.ajax({
        url: urlApi + '/sertifikasiperizinan/update/' + self.id,
        data: dataku,
        cache: false,
        contentType: false,
        processData: false,
        method: 'POST',
        headers: ApiService.headerAPi(),
        success: function(response) {
          if (response.status == 'ok') {
            self.makeToast('success', 'Data berhasil diubah')
            self.$router.push('/sertifikasiperizinan/')
          } else if (response.status == 'validation_error') {
            self.errors = response.errors
            self.showDismissibleAlert = true
            self.loading = false
            self.$refs['error-modal'].show()
          }
        },
      })
    },

    itemSelectedKategoriPeralatan(item) {
      this.form.kategori_peralatan = item
    },
    itemSelectedLokasiSertifikasiPerizinan(item) {
      this.form.lokasi_sertifikasi_perizinan_id = item
    },
    setLabel(item) {
      return item.name
    },
    inputChangeKategoriPeraltan(text) {
      // your search method
      var self = this
      ApiService.get('/sertifikasiperizinan/showkategoriperalatan/' + text)
        .then(({ data }) => {
          if (data.status == 'ok') {
            var kategori_peralatan = data.data
            self.optionsKategori.splice(0, self.optionsKategori.length)

            for (let i = 0; i < kategori_peralatan.length; i++) {
              self.optionsKategori.push({
                id: kategori_peralatan[i].id_kategori_peralatan,
                name: kategori_peralatan[i].nama_kategori_peralatan,
              })
            }
          }
        })
        .catch(response => {
          console.log(response)
        })

      this.form.kategori_peralatan = text
    },
    inputLokasiSertifikasiPerizinan(text) {
      // your search method
      var self = this
      ApiService.get('/sertifikasiperizinan/showlokasisertifikasiperizinan/' + text)
        .then(({ data }) => {
          if (data.status == 'ok') {
            var lokasi_sertigikasi_perizinan = data.data
            self.optionsLokasi.splice(0, self.optionsLokasi.length)

            for (let i = 0; i < lokasi_sertigikasi_perizinan.length; i++) {
              self.optionsLokasi.push({
                id: lokasi_sertigikasi_perizinan[i].id_lokasi_sertifikasi_perizinan,
                name: lokasi_sertigikasi_perizinan[i].nama_lokasi_sertifikasi_perizinan,
              })
            }
          }
        })
        .catch(response => {
          console.log(response)
        })

      this.form.lokasi_sertifikasi_perizinan_id = text
    },
    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    makeToast(variant = null, $content) {
      this.$bvToast.toast($content, {
        title: `${variant || 'default'}`,
        variant: variant,
        toaster: 'b-toaster-top-center',
        solid: true,
      })
    },
  },
}
</script>
